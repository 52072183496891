<template>
  <div>
    <HeaderPanel
      title=" Notification"
      :filter="filter"
      placeholder="Search Name, Promotion Type News"
      @search="getList"
      :hasDropdown="false"
      :hasFilter="false"
      routerPath="/notification/details/0"
    />
    <div class="bg-white border-red p-2 p-lg-3 mt-3">
      <b-row>
        <b-col>
          <b-table
            responsive
            striped
            hover
            :fields="fields"
            :items="items"
            :busy="isBusy"
            show-empty
            empty-text="No matching records found"
          >
            <template v-slot:cell(title)="data">
              <router-link
                :to="'/notification/details/' + data.item.id_notification"
              >
                <u> {{ data.item.title }}</u>
              </router-link>
            </template>
            <template v-slot:cell(schedule_time)="data">
              <span>
                {{
                  new Date(data.item.schedule_time)
                    | moment($store.state.formatDateTime)
                }}
              </span>
            </template>
            <template v-slot:cell(status_name)="data">
              <span
                style="color: #ffcc00"
                v-if="data.item.status_name === 'Wait Send'"
              >
                {{ data.item.status_name }}
              </span>
              <span
                class="text-success"
                v-else-if="data.item.status_name === 'Send'"
              >
                {{ data.item.status_name }}
              </span>
              <span class="text-error" v-else>
                {{ data.item.status_name }}
              </span>
            </template>
            <template v-slot:cell(action)="data">
              <div class="d-flex justify-content-center">
                <router-link
                  :to="'/notification/details/' + data.item.id_notification"
                >
                  <b-button variant="link" class="text-warning px-1 py-0">
                    <font-awesome-icon icon="pencil-alt" title="Edit" />
                  </b-button>
                </router-link>
              </div>
            </template>
            <template v-slot:table-busy>
              <div class="text-center text-black my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong class="ml-2">Loading...</strong>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <Pagination
        :pageOptions="pageOptions"
        :filter="filter"
        :rows="rows"
        @pagination="pagination"
        @handleChangeTake="hanndleChangePerpage"
      />
    </div>
  </div>
</template>

<script>
import HeaderPanel from "@/components/HeaderPanel";
import Pagination from "@/components/Pagination";
export default {
  name: "NotificationIndex",
  components: { HeaderPanel, Pagination },
  data() {
    return {
      fields: [
        // {
        //   key: "images",
        //   label: "Thumbnail"
        // },
        {
          key: "product_Type",
          label: "Promotion Type News",
          class: "w-200",
        },
        {
          key: "title",
          label: "Notification Name",
          class: "w-200",
        },
        {
          key: "content",
          label: "Description",

          class: "w-200",
        },
        {
          key: "schedule_time",
          label: "Publish Date",
          class: "w-200",
        },
        {
          key: "status_name",
          label: "Status",
        },
        {
          key: "action",
          label: "Action",
        },
      ],
      items: [],
      isBusy: false,
      checkAllPromotion: false,
      selectAllPromotion: false,
      checkAllStatus: false,
      selectAllStatus: false,
      rows: 0,
      currentPage: 1,
      perPage: 10,
      filter: {
        search: "",
        promotion: [],
        status: [],
        page: 1,
        take: 10,
        SortByOrderOrId: 0,
        startDate: "",
        endDate: "",
      },
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      selected: 0,
    };
  },
  created: function () {
    this.getList();
  },
  methods: {
    getList: async function () {
      this.isBusy = true;
      await this.$store.dispatch("ActionGetNotificationPerPage", this.filter);
      var data = await this.$store.state.moduleConnectAPI
        .stateGetNotificationPerPage;
      if (data.result == 1) {
        this.items = data.detail.detail;
        this.rows = data.detail.total_count;
        this.isBusy = false;
      }
    },
    pagination(page) {
      this.filter.page = page;
      this.getList();
    },
    getDataByStatus() {
      this.$refs.filterSidebar.hide();
      this.getList();
    },
    hanndleChangePerpage(value) {
      this.filter.page = 1;
      this.filter.take = value;
      this.getList();
    },
    checkPromotionLength() {
      if (this.filter.promotion.length == 2) {
        this.selectAllPromotion = true;
      } else {
        this.selectAllPromotion = false;
      }
    },
    checkAllSelectPromotion() {
      if (this.selectAllPromotion) {
        this.filter.promotion = [];
      } else {
        this.filter.promotion = [1, 0];
      }
    },
    checkStatusLength() {
      if (this.filter.status.length == 2) {
        this.selectAllStatus = true;
      } else {
        this.selectAllStatus = false;
      }
    },
    checkAllSelectStatus() {
      if (this.selectAllStatus) {
        this.filter.status = [];
      } else {
        this.filter.status = [1, 0];
      }
    },
  },
};
</script>

<style scoped>
.image {
  width: 100%;
  padding-top: 42.9%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
</style>
